@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {
    --primaryColor: #D9EDFF;
    --blueColor: #0581F8;
    --whiteColor: white;
    --blackColor: black;
    --darkColor: #2C2C2C;
  }

.tago_cashies_style {
    font-family: "Roboto", sans-serif;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
    color: var(--chakra-colors-secondary);
}

.get_tago {
    background: var(--blueColor);
    border-radius: 7px;
    padding: 5px;
    color: var(--whiteColor);
}

.accordion_body {
    width: 600px;
    border-radius: 7px;
}

.accordion_style {
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--darkColor);
    margin: 0;
}

.accordion_detail {
    font-family: var(--chakra-fonts-body);
    color: var(--chakra-colors-text);
    font-size: 15px;
    margin-top: 10px;
    font-weight: 400;
    padding-left: 37px;
    text-align: left;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.accordion_detail.open {
    max-height: 200px; 
}

.tago_img {
    width: 425px;
    height: 512px;
    animation: shake 3s ease-in-out infinite; /* Total duration of animation cycle: 3s (1s animation + 2s delay) */
}

@keyframes shake {
    0%, 100% { transform: translateX(0); } /* Initial and final position */
    33.33% { transform: translateX(-10px) rotate(-5deg); } /* Animation for the first second */
    66.66% { transform: translateX(10px) rotate(5deg); } /* No transformation during the delay */
}


@media (min-width: 1200px) and (max-width: 1400px) {
    .accordion_body {
        width: 550px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .accordion_body {
        width: 450px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .accordion_body {
        width: 500px;
    }
    .tago_accordion{
        padding-top: 2rem;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .accordion_body {
        width: 470px;
    }
    .tago_accordion{
        padding-top: 2rem;
    }
}

@media (max-width: 576px) {
    .accordion_body {
        width: 350px;
    }
    .tago_accordion{
        padding-top: 2rem;
        margin-bottom: 1rem;
    }
    .tago_img{
        max-width: 100%;
        height: 512px;
    }
    .accordion_style {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--darkColor);
        margin: 0;
    }
}
@media (max-width: 510px) {
   .tago_cashies_style {
    font-size: 2.5rem;
   }
}
@media (max-width: 433px) {
   .tago_cashies_style {
    font-size: 2.3rem;
   }
   .tago_img{
    width: 420px;
    height: 500px;
}
}
@media (max-width: 400px) {
   .tago_cashies_style {
    font-size: 2.1rem;
   }

   .tago_img{
    width: 400px;
    height: 450px;
}
}
@media (max-width: 370px) {
   .tago_cashies_style {
    font-size: 2rem;
   }
}