@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --primaryColor: #D9EDFF;
    --blueColor: #0581F8;
    --whiteColor: white;
    --blackColor: black;
    --darkColor: #2C2C2C;
}

.lottie_single {
    padding: 3em;
    background-color: white;
    border-radius: 40px;
    width: 500px;
    height: 500px;
}

/* .lottie_single:nth-child(1) {
    height: 650px !important;
    width: 540px !important;
}
.lottie_single:nth-child(2) {
    height: 650px !important;
    width: 540px !important;
}
.lottie_single:nth-child(3) {
    height: 650px !important;
    width: 540px !important;
} */
.paying_head {
    background: var(--blueColor);
    border-radius: 7px;
    padding: 5px;
}

.paying_btn {
    border: none;
    background: none;
}

.paying_style {
    font-family: "Roboto", sans-serif;
    font-size: 3rem;
    font-weight: 900;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whiteColor);
    /* margin: 0.65em; */
}

.paying_content {
    font-family: "Roboto", sans-serif;
    font-size: 78px;
    font-weight: 700;
    line-height: 111px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF33;
    margin: 0;
}

.paying_content_one {
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    /* color: var(--whiteColor); */
    margin: 0;
}

.paying_content_two {
    font-family: "Roboto", sans-serif;
    font-size: 21px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whiteColor);
    margin: 0;
    padding-top: 0.5rem;
}

.paying_img {
    width: 500px;
    height: 475px;
}

.paying_col {
    display: flex;
    justify-content: flex-end;
    padding-top: 240px;
}

.paying_pading {
    /* padding-left: 2rem; */
    /* padding-right: 3rem; */
}

.par-process {
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: left;
    /* margin: 1.2em; */
    line-height: 15px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .paying_pading {
        /* padding-left: 2rem; */
        /* padding-right: 2rem; */
    }

    .lottie_single {
        padding: 3em;
        background-color: white;
        border-radius: 30px;
    }

    .paying_col {
        padding-top: 240px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .paying_pading {
        /* padding-left: 2rem; */
        /* padding-right: 2rem; */
    }

    .lottie_single {
        padding: 2em;
        background-color: white;
        border-radius: 20px;
    }

    .paying_col {
        padding-top: 270px;
    }
}

@media (max-width: 992px) {
    .lottie_box_process {
        background: white;
    }

    .lottie_single {
        padding: 1px;
        background-color: white;
    }

    .paying_col {
        padding-top: 70px;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 650px) {
    .par-process {
        line-height: 2em;
    }
}

@media (max-width: 500px) {
    .paying_style {
        font-size: 2.3rem;
    }

    .par-process {
        line-height: 1.7em;
    }

}

@media (max-width: 390px) {
    .paying_style {
        font-size: 2.1rem;
    }

    .par-process {
        font-size: 1.08rem;
        line-height: 1.4em;
    }
}

@media (max-width: 576px) {
    .lottie_single {
        text-align: center;
        border: #052A4F solid 2px;
        border-radius: 40px;
        margin-top: 2em;
        width: 400px;
        height: 400px;
    }

    /* .lottie_single:nth-child(1) {
        height: 380px !important;
        width: 450px !important;
    }
    .lottie_single:nth-child(2) {
        height: 380px !important;
        width: 450px !important;
    }
    .lottie_single:nth-child(3) {
        height: 380px !important;
        width: 450px !important;
    } */
    .paying_col {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .paying_pading {
        /* padding-left: 1.5rem; */
        /* padding-right: 2rem; */
    }
}