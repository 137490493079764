@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.second_sec_text {
  line-height: 1.7em;
}

.App {
  transition: transform 1s;
}

.slide-in {
  transform: translateY(0);
}

.slide-out {
  transform: translateY(-50%);
}


.bold-text-style {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}