.privacy-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.privacy-container-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.top-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    line-height: 100px;
    background: linear-gradient(160deg, rgba(0, 68, 255, 0.1) 75%, transparent 70%);
}

.top-section h1 {
    font-size: 4rem;
    font-weight: 900;
    text-align: left;
}

.top-section p {
    font-size: 1.6rem;
}

.policy-details {
    padding: 20px;
    text-align: center;
    width: 80%;
}

.policy-details h1 {
    font-weight: 700;
    text-align: left;
    line-height: 1.7em;
}

.policy-details h4 {
    font-weight: 500;
    text-align: left;
    line-height: 2em;
}

.policy-details p{
    text-align: left;
    line-height: 1.9em;
    font-size: 1.1rem;
    font-weight: 500;
}

.policy-details ol {
    text-align: left;
    font-weight: 500;
    font-size: 1.1rem;
}

.policy-details ul {
    text-align: left;
    font-weight: 500;
    font-size: 1.1rem;
}

.policy-details li {
    line-height: 2.5em;
}

.custom-list {
    list-style-position: outside;
    padding-left: 3em;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .top-section {
        height: 50vh;
    }
    .top-section h1 {
        font-size: 2rem;
    }

    .top-section p,
    .policy-details p,
    .policy-details ol,
    .policy-details ul {
        font-size: 0.9rem;
    }

    .policy-details {
        width: 100%;
    }

    .policy-details h1 {
        font-size: 1.4rem;
    }

    .policy-details h4 {
        line-height: 1.5em;
    }
}

.fees-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.fees-table th, .fees-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.fees-table th {
    background-color: #f2f2f2;
    text-align: left;
}

@media screen and (max-width: 600px) {
    .fees-table {
        overflow-x: auto;
        display: block;
    }

    .fees-table thead, .fees-table tbody, .fees-table th, .fees-table td, .fees-table tr {
        display: block;
    }

    .fees-table tr {
        border-bottom: 1px solid #ddd;
    }

    .fees-table td {
        text-align: left;
        border-bottom: none;
        border-top: 1px solid #ddd;
        padding-left: 50%;
        position: relative;
    }

    .fees-table td:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
    }
}
