@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: "Nexa";
  src: url("assets/fonts/nexa-regular.ttf");
}

img,
svg {
  display: inline;
}

.scroll {
  -ms-overflow-style: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.cookies-content {
  width: 100%;
  height: 170px;
  opacity: 0.9;
  background-color: #000;
  position: fixed;
  bottom: 0;
  padding: 30px 15px;
  line-height: 36px;
  display: grid;
}

.cookies-content p {
  display: inline-block;
  /*  width: 100%;*/
  color: #fff;
  letter-spacing: normal;
}

.cookies-content .desktop {
  display: inline-block;
  text-align: left;
  line-height: 2em;
}

.cookies-content .mobile {
  display: none;
}

.cookies-content .btn-accept {
  position: absolute;
  right: 35px;
  top: 45px;
  padding: 2rem 6rem;
  color: white;

}

.desktop p {
  font-size: 1.2rem;
  width: 76%;
  /* Use max-width instead of width to make it responsive */
  margin: 0 auto;
  /* Center the content */
}

.desktopMode span h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.hero_animation {
  width: 474px;
  height: 365px;
}

/* .safe_width {
  font-size: 1.7rem;
  font-weight: 600;
  width: 26ch;
  line-height: 1.5em;
} */

.google_play:hover {
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.second_cards {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  padding: 32px 22px;
  text-align: center;
  background: #000000;
}

.second_cards_heading {
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  font-size: 30px;
  font-weight: 900;
  color: white;
  line-height: 1.2;
  margin: 0;
}

.second_cards_details {
  font-family: var(--chakra-fonts-heading);
  color: white;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.active {
  color: #7fffd4;
}

.inactive {
  color: white;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .hero_animation {
    width: 390px;
    height: 350px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .hero_animation {
    width: 300px;
    height: 320px;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .heros_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heros_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .heros_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heros_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .hero_animation {
    width: 335px;
    height: 365px;
  }

  .heros_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* .safe_width {
    width: 25ch;
  } */
}


@media (max-width: 1135px) {
  .cookies-content {
    width: 100%;
    height: 170px;
    opacity: 0.9;
    background-color: #000;
    position: fixed;
    bottom: 0;
    padding: 30px 15px;
    line-height: 36px;
    display: grid;
  }

  .cookies-content .btn-accept {
    position: absolute;
    right: 35px;
    top: 45px;
    padding: 1.6rem 4rem;
    color: white;

  }


  .desktop p {
    font-size: 1rem;
    width: 76%;
    /* Use max-width instead of width to make it responsive */
    margin: 0 auto;
    /* Center the content */
  }

  .desktopMode span {
    font-size: 1.3rem;
    text-transform: uppercase;
  }
}

@media (max-width: 976px) {
  .cookies-content .btn-accept {
    position: absolute;
    right: 35px;
    top: 45px;
    padding: 1.4rem 3rem;
    color: white;

  }


  .desktop p {
    font-size: 1rem;
    width: 80%;
    /* Use max-width instead of width to make it responsive */
    margin: 0 auto;
    /* Center the content */
  }

  .desktopMode span {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

@media (max-width: 870px) {
  .cookies-content .btn-accept {
    position: absolute;
    right: 35px;
    top: 45px;
    padding: 1.2rem 1rem;
    color: white;

  }


  .desktop p {
    font-size: 1rem;
    width: 86%;
    /* Use max-width instead of width to make it responsive */
    margin: 0 auto;
    /* Center the content */
  }

  .desktopMode span {
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}

@media (max-width: 770px) {
  .cookies-content {
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7em;
    width: 100%;
    height: auto;
    opacity: 0.97;
    background-color: #000;
    position: fixed;
    bottom: 0;
    padding: 0 5px;
    padding-bottom: 25px;
    text-align: center;
  }

  .mobileMode span {
    font-size: 0.87rem;
    text-transform: uppercase;
  }

  .cookies-content img {
    margin: 0;
    padding: 0;
    text-align: center;

  }

  .mobile p {
    line-height: 28px;
    text-align: left;
  }

  .cookies-content .desktop {
    display: none;
  }

  .cookies-content .mobile {
    top: 0;
    width: 100%;
    font-size: 14px;
    display: block;
  }

  .btn-accept-mobile {
    width: 100%;
    background-color: blue;
    margin-top: 40px;
    height: 50px;
  }

}

.navbar-toggler:focus {
  box-shadow: none !important;
}

/* START PAGES CSS */

#recipient_gif,
#amount_gif,
#confirm_pay_gif,
#pnf_lottie,
#contact_lottie {
  pointer-events: none !important;
}

#recipient_gif svg,
#amount_gif svg,
#confirm_pay_gif svg {
  transform: unset !important;
}

.button_color {
  background: #0581f8 !important;
}

/* begin hero section */
.hero_section {
  border-radius: 0px 0px 0px 120px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.6) -3.43%,
      rgba(198, 220, 255, 0.6) 104.67%);
}



.hero_title {
  color: #000;
  /* font-family: DM Serif Display; */
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.hero_title_tago {
  background: linear-gradient(101deg, #0c91da 4.45%, #254ca6 98.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero_subtitle {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.fasst_subtitle {
  color: var(--chakra-colors-text);
  font-size: 24px;
  font-family: var(--chakra-fonts-body);
  font-weight: 700;
  /* color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; */
}

.hero-header-title {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.sub_text {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.sub_title {
  color: #282828;
  font-family: "Roboto", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.globally_title {
  font-size: 35px;
}

.app_link {
  border-radius: 21px;
  background: #0581f8;
  width: 150px;
}

.margin_top {
  margin-top: 75px;
}

.fasst_title {
  /* font-family: DM Serif Display; */
  font-size: 51px;
  /* line-height: normal; */
}

.svg_background {
  border-radius: 4px;
  background: #fff;
  box-shadow: -2px 4px 8px 2px rgba(49, 118, 238, 0.2);
  width: 38px;
  height: 37px;
}


.fasst_feature_description {
  color: var(--chakra-colors-text);
  font-size: 15px;
  font-weight: 400;
  font-family: var(--chakra-fonts-body);
  /* color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; */
}

.fasst_fast_text_width {
  max-width: 156px;
  width: 100%;
}

.fasst_affordable_text_width {
  max-width: 216px;
  width: 100%;
}

.fasst_safe_text_width {
  max-width: 176px;
  width: 100%;
}

.fasst_secure_text_width {
  max-width: 225px;
  width: 100%;
}


.fast_feature::before,
.fast_feature::after {
  content: "";
  position: absolute;
  width: 2.5px;
  height: 70px;
  /* background-color: #afcce4f0; */
  background-color: #0581f8;
}

.fast_feature::before {
  top: 0;
  left: 0;
}

.fast_feature::after {
  bottom: 0;
  right: 0;
}

/* end hero section */

.padding_top {
  padding-top: 150px;
}

.payment_process_card {
  padding: 25px 50px;
  border-radius: 21px;
  /* background: #f5f9ff; */
}

.number_block {
  display: flex;
  padding: 25px 34px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40.5px;
  background: #c6ddff;
  width: 80px;
  height: 44px;
  position: absolute;
  z-index: 1;
  margin-left: -50px;
}

.payment_process_card_text {
  line-height: 30px;
  max-width: 352px;
  width: 100%;
}

.tagocash_features_title {
  color: #0581f8;
}

.d-flex.active .number_block {
  background: #0581f8;
}

.d-flex.active .fw-bold {
  font-weight: 700;
}

.d-flex.active .payment_process_card {
  background: #f5f9ff;
}

.d-flex.active .hero_subtitle {
  color: #0581f8;
}

.tagocash_features_text {
  font-family: "Roboto", sans-serif;
  padding-left: 38px;
  width: 575px;
}

.download_app_section {
  background: #0A2540;
  height: 180px;
}

.download_app_subtext {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.app_link_position {
  position: absolute;
  right: 15%;
  top: 40%;
}

/* begin get more with tago cash */

.more_with_tago_small {
  display: none;
}

.get_more_title {
  font-size: 48px;
}

.grid-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 3fr 1fr;
  gap: 15px;
}

.item1 {
  grid-row: span 2;
  margin-left: 25px;
}

.get_feature_box {
  border-radius: 11.75px;
  background: #fff;
  box-shadow: 3.917px 3.917px 23.5px 0px rgba(146, 201, 255, 0.12) inset,
    0px 3.917px 7.833px 0px rgba(111, 185, 239, 0.2);
}

.get_more_feature_padding {
  padding: 25px 30px 10px;
}

.get_more_subtext {
  line-height: 32px;
}

.coinstar_text {
  max-width: 295px;
  width: 100%;
}

.linked_bank_text {
  max-width: 175px;
  width: 100%;
}

.cards_text {
  max-width: 222px;
  width: 100%;
}

.money_gram_text {
  max-width: 285px;
  width: 100%;
}

/* begin blog section */
.blog_title {
  font-size: 36px;
  text-align: center;
}

.cards {
  width: 368px;
  height: 495px;
  transition: transform 0.2s;
}

.card {
  margin-top: 2.2em;
}

.cards:hover {
  -ms-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.blog-cards-container {
  display: flex;
  justify-content: space-around;
  /* gap: 2px; */
  /* Adjust as needed */
  flex-wrap: wrap;
  /* Allow cards to wrap to the next line */
}

.blog_type_text {
  color: #0581f8;
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.filled_circle {
  height: 4px;
  width: 4px;
  background: #0581f8;
  border-radius: 50%;
  margin-top: 6px;
}

.blog_img_size_new {
  width: 800px;
  height: 480px;
}

/* end blog section */

/* begin news letter section */
.news_letter_section {
  background: #0A2540;
}

.first_to_know_color {
  color: #ffffff !important;
}

.subscribe_block {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  width: 600px;
}

input[type="email"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
textarea:focus,
textarea.form-control:focus,
input[type="checkbox"]:focus {
  box-shadow: inset 0 -1px 0 transparent;
}

/* end news letter section */

/* begin footer */
.footer_title {
  line-height: normal;
}

.footer_links_block {
  display: flex;
  gap: 120px;
}

.gap {
  gap: 120px;
}

.footer_subtext {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer_subtext_width {
  max-width: 376px;
  width: 100%;
}

.footer_page_link {
  text-decoration: none;
  color: #000000;
}

.trademark {
  color: rgba(0, 0, 0, 0.4);
}

/* end footer */

/*begin cookies */
.cookie_bg {
  background: #eff5ff;
}

.cookie_title {
  color: #000;
  /* font-family: DM Serif Display; */
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pointer {
  cursor: pointer;
}

.cookie_subtext {
  color: #000;
  /* font-family: Source Sans 3; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 116.667% */
}

/* #rcc-confirm-button{
  display: none !important;
} */
/* end cookies */

/* contact us page */
.contact_section {
  background: #f6f6f6;
}

.contact_title {
  font-size: 57px;
}

.contact_subtext {
  font-size: 17px;
  line-height: normal;
  max-width: 550px;
  width: 100%;
}

.left_sided_contact {
  flex: 2;
}

.form_section {
  flex: 3;
}

/* news letter page  */
.img_block {
  flex: 1.75;
}

.subscribe_block {
  flex: 2;
}

/* blogs page */
.blogs_subtext {
  color: #585858;
  line-height: normal;
}

.featured_article {
  border-radius: 21px;
  background: #c6dcff;
  width: 134px;
  text-align: center;
}

.all_blogs_title {
  width: 100%;
}

.featured_article_text {
  font-size: 14px;
  font-weight: 600;
}

.blogs_page_subtext {
  font-size: 16px;
}

.card_title {
  /* font-family: "Roboto", sans-serif; */
  font-weight: 600;
  max-width: 473px;
  width: 100%;
}

.card_text {
  font-size: 20px;
  max-width: 473px;
  width: 100%;
}

/* blog content page */
.blog_content_block {
  position: relative;
}

.left_blog_content {
  flex: 1.25;
}

.right_side_block {
  flex: 0.75;
}

.blog_content_title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.blogs_content_subtext {
  line-height: normal;
}

.right_side_content {
  position: sticky;
  top: 175px;
  z-index: 1;
}

.download_block {
  border-radius: 5px;
  background: #ddebff;
  width: 360px;
  /* height: 380px; */
  margin: 0 auto;
}

.fast_secure_title {
  line-height: 48px;
}

.img_app_block {
  position: relative;
}

.app_link_block {
  position: absolute;
  top: 40px;
  left: 105px;
}

.link_block {
  width: 360px;
  margin: 0 auto;
}

.filled_circle_blog {
  height: 6px;
  width: 6px;
  background: #000;
  border-radius: 50%;
  margin-top: 8px;
}

.question_blog {
  font-size: 20px;
}

.more_article_title {
  font-size: 36px;
}

.social_links {
  display: none;
}

.footer_text_mobile {
  display: none;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #edf4ff !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  border-color: #585858 !important;
  outline: #585858 !important;
  box-shadow: none !important;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-button:hover {
  z-index: 1 !important;
}

.faq_bg {
  background-color: #edf4ff !important;
}

/* begin signup page */
.free_plan {
  border-radius: 5px;
  background: linear-gradient(180deg, #6fb9ef 0%, #138be4 100%);
}

.annual_plan {
  border-radius: 5px;
  background: linear-gradient(180deg, #00327d 0%, #1b60c8 100%);
}

.plan_title {
  color: #fff;
  /* font-family: DM Serif Display; */
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.plan_duration {
  border-radius: 2.061px;
  background: rgba(21, 25, 64, 0.56);
  max-width: 140px;
  width: 100%;
  margin-inline: auto;
}

.membership_title {
  color: #000;
  /* font-family: DM Serif Display; */
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.modal_title {
  color: #0581f8;
  /* font-family: DM Serif Display; */
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
}

.modal_subtext {
  color: #000;
  /* font-family: DM Serif Display; */
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* end signup page */

/* begin phone number input field css  */
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: unset !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: unset !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 15px !important;
  background: transparent !important;
}

.react-tel-input .form-control {
  padding: 12px 50px !important;
  font-size: 16px !important;
  color: #000000 !important;
  font-weight: 400 !important;
  width: 100% !important;
  border-radius: 24px !important;
  border: 2px solid transparent !important;
  line-height: 1.4375em !important;
  height: auto !important;
}

.react-tel-input .country-list {
  text-align: left;
  width: 260px !important;
  margin: 10px 0 10px 15px !important;
  border-radius: 4px !important;
}

.wallet_input_bg .form-control {
  background-color: #6c757d1a !important;
}

/* end phone number input field css  */

/* mobile version */
@media (min-width: 360px) and (max-width: 767.95px) {

  /* home */
  .hero_section {
    border-radius: 0px 0px 0px 71px;
  }

  .subtitle_small {
    font-size: 18px;
    line-height: normal;
  }

  .globally_title {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .hero_subtext {
    font-size: 14px;
  }

  .currencyanimation_image_size {
    width: 100%;
    height: 100%;
  }

  .fasst_mobile {
    width: 351px;
    height: 183px;
  }

  .why_tago_title {
    font-size: 23px;
  }

  .cash_king_title {
    font-size: 23px;
    line-height: normal;
  }

  .more_with_tago_small {
    display: block;
  }

  .fasst_tagocash_logo {
    width: 164px;
    height: 32px;
  }

  .fasst_feature_description {
    font-size: 16px;
    line-height: 26px;
    max-width: 100%;
    width: 100%;
  }

  .fasst_feature {
    flex: 1;
  }

  .more_with_tago_features {
    display: none;
  }

  .get_more_title {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .cards_bg {
    background: #f4f8ff;
  }

  .cards:hover {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .get_more_width {
    max-width: 200px;
    width: 100%;
  }

  .mobile_card {
    border-radius: 3px 7px 49px 7px;
  }

  .bank_accout_card {
    border-radius: 3px 49px 7px 7px;
  }

  .store_card {
    border-radius: 3px 7px 7px 49px;
  }

  .atm_card {
    border-radius: 49px 7px 7px 7px;
  }

  .download_app_section {
    height: 300px;
  }

  .first_to_know_color {
    font-size: 36px;
    line-height: 40px;
  }

  .download_app_subtext {
    font-size: 14px;
  }

  .blog_title {
    font-size: 24px;
  }

  .simple_steps_text {
    font-size: 14px;
    line-height: normal;
  }

  .padding_top {
    padding-top: 50px;
  }

  .choose_recipient_size {
    width: 258px !important;
    height: 280px !important;
  }

  #recipient_gif svg,
  #amount_gif svg,
  #confirm_pay_gif svg,
  #pnf_lottie svg {
    width: 400px !important;
  }

  #recipient_gif div,
  #amount_gif div,
  #confirm_pay_gif div,
  #pnf_lottie div {
    width: 360px !important;
    margin: 0 auto !important;
  }

  .numbers_mobile {
    margin-bottom: -30px;
  }

  .feature_cards {
    border-radius: 5px;
    background: #f2f9ff;
  }

  .feature_card_title {
    color: #0581f8;
    /* font-family: DM Serif Display; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .payment_process_card {
    background: #f2f9ff;
  }

  .fs-5.active {
    background: #0581f8;
    border: 0 !important;
  }

  .carousel-indicators {
    top: 180px;
  }

  .blog_carousel_indicators {
    top: 460px;
  }

  .slick-dots li.slick-active button:before {
    color: #138be4 !important;
    opacity: 1 !important;
  }

  .slick-dots li button:before {
    font-size: 10px !important;
    opacity: 0.25;
    color: #138be4 !important;
  }

  .slick-arrow {
    display: none !important;
  }

  .plans_carousel_indicators {
    top: 580px;
  }

  .carousel-indicators [data-bs-target] {
    height: 8px !important;
    width: 8px !important;
    border-radius: 50% !important;
  }

  /* newsletter section */
  .news_letter_size {
    width: 344px;
    height: 141px;
  }

  .newsletter_content_mobile {
    position: absolute;
    top: 15%;
    left: 38%;
  }

  .subscribe_field_mobile {
    position: absolute;
    bottom: 10%;
    width: 95% !important;
    margin-left: 2%;
    background: #fff;
    padding: 5px 10px;
    border-radius: 100px;
  }

  /* blogs */
  .hero_title {
    font-size: 36px;
    line-height: 42px;
  }

  .blogs_subtext {
    font-size: 16px;
  }

  .featured_article {
    width: 100%;
  }

  .card_title {
    font-size: 21px;
    max-width: 100%;
    width: 100%;
    line-height: normal;
  }

  .cards {
    width: 99%;
    margin-top: 25px;
    margin: 0 auto;
  }

  .blog_img_size {
    width: 100%;
    height: 248px;
  }

  .question_blog {
    font-size: 18px;
  }

  .more_article_title {
    font-size: 24px;
  }

  /* footer */
  .footer_links_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .link_mobile,
  .footer_text {
    display: none;
  }

  .social_links {
    display: flex;
    justify-content: center;
  }

  .footer_text_mobile {
    display: block;
    margin: 25px auto 0;
  }

  .footer_block_center {
    text-align: center;
  }

  .footer_logo_size {
    width: 144px;
    height: 30px;
  }

  .footer_title {
    font-size: 20px;
  }

  .footer_subtext {
    font-size: 14px;
  }

  .margin_top {
    margin-top: 10px;
  }

  /* contacts */
  .contact_block {
    display: flex;
    align-items: center;
  }

  .blue_background {
    max-width: 430px;
    width: 100%;
    max-height: 338px;
    height: 100%;
  }

  .contacts_size {
    width: 70px;
    height: 70px;
  }

  .contact_title {
    font-size: 28px;
  }

  .contact_subtext {
    font-size: 13px;
  }

  /* subscribe page */

  .subscribe_block {
    background: none;
    padding: 0;
    width: 100%;
  }

  .membership_title {
    font-size: 26px;
    line-height: 30px;
  }

  /* cookie */
  .cookie_title {
    font-size: 19px;
    line-height: 26px;
  }

  .modal_title {
    font-size: 20px;
    line-height: 28px;
  }

  .modal_subtext {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {

  /* footer */
  .gap {
    gap: 55px;
  }

  .footer_links_block {
    gap: 55px;
  }

  /* contact us */
  .left_sided_contact {
    flex: 1;
  }

  .form_section {
    flex: 1;
  }

  .cards {
    max-width: 225px;
    width: 100%;
  }

  .fasst_tab {
    flex-wrap: wrap !important;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .news_letter_size {
    width: 325px;
    height: 225px;
  }

  .sub_title {
    font-size: 25px;
  }

  .sub_text {
    font-size: 18px;
    line-height: normal;
  }

  .subscribe_field_tab {
    width: 100%;
  }

  .blog_img_size {
    width: 100%;
    height: 100%;
  }

  .featured_block_gap {
    gap: 25px;
  }

  /* home */
  .currencyanimation_image_size {
    width: 575px;
  }

  .fasst_mobile {
    width: 350px;
    height: 210px;
  }

  .tagocash_different_dimension {
    width: 375px;
    height: 450px;
  }

  .tagocash_features_text {
    width: 355px;
  }

  #recipient_gif div,
  #amount_gif div,
  #confirm_pay_gif div {
    width: 400px !important;
  }

  .fasst_tab {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .hero_title {
    font-size: 41px;
    line-height: 40px;
  }

  .hero_subtitle {
    font-size: 24px;
    line-height: 35px;
  }

  .fasst_tagocash_logo {
    width: 175px;
    height: 30px;
  }

  .fasst_title {
    font-size: 38px;
  }

  .fasst_logo_size {
    width: 258px;
    height: 52px;
  }

  .fasst_feature_description {
    font-size: 20px;
  }

  .get_more_title {
    font-size: 30px;
  }

  .get_more_logo {
    width: 170px;
    height: 35px;
  }

  .get_more_feature_padding {
    padding: 5px 14px 0px;
  }

  .subscribe_block {
    width: 100%;
    padding: 0;
  }

  .contact_subtext {
    font-size: 16px;
    line-height: 25px;
  }
}


@media (max-width: 1399.5px) {

  .hero_title {
    font-size: 3.5rem !important;
  }

  .hero_subtitle {
    font-size: 2rem !important;
  }
}

@media (max-width: 1199px) {

  .hero_title {
    font-size: 3rem !important;
  }

  .hero_subtitle {
    font-size: 1.4rem !important;
  }
}

@media (max-width: 992px) {
  .hero-contai {
    text-align: center;
  }

  .hero_title {
    font-size: 3rem !important;
    font-weight: 900;
  }

  .hero_subtitle {
    font-size: 1.6rem !important;
    font-weight: 800;

  }

  .fast-last-style {
    width: 15ch !important;
    margin-top: 1.8em !important;
  }


}

@media (max-width: 768px) {
  .fasst-text-line {
    /* width: 17ch !important; */
    line-height: 1.3em !important;
  }

  .hero_title {
    font-size: 2.7rem !important;
    font-weight: 900 !important;
    line-height: 1.3em !important;
  }

  .hero_subtitle {
    font-size: 1.2rem !important;
    font-weight: 900 !important;
  }

  .sub-title-style {
    font-size: 1.05rem !important;
    font-weight: 700 !important;
  }

  .wrap_mobile {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    text-align: center;
  }

  .wdth-style {
    width: 16.5ch;
  }

  .pixel-styles {
    line-height: 10px;
  }


}

.wrap_mobile {
  width: 100%;
  text-align: center;
}